import { _axios } from '@/plugins/axios'

export const apiEmergencyList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/emergency/list/apply/info`,
		method: method,
		data: dataJson
	})
}
export const apiEmergencyApplyTryCalculate = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/emergency/applyTryCalculate`,
		method: method,
		data: dataJson
	})
}
export const apiEmergencyApplyConfirmLoanInfo = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/emergency/applyConfirmLoanInfo`,
		method: method,
		data: dataJson
	})
}
export const apiEmergencyApply = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/emergency/info/apply/save`,
		method: method,
		data: dataJson
	})
}
export const apiEmergencyDetail = (dataJson, notify, method = 'get') => {
	return _axios({
		url: `/car/loan/emergency/${dataJson.id}`,
		method: method,
		notify: notify ? true : false
	})
}
export const apiEmergencyApplyCheck = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/emergency/list/apply/check`,
		method: method,
		data: dataJson
	})
}
export const apiEmergencyDisburseInfo = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/emergency/list/disburse/info`,
		method: method,
		data: dataJson
	})
}
export const apiEmergencyDisburseCheck = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/emergency/list/disburse/check`,
		method: method,
		data: dataJson
	})
}
export const apiEmergencyWaitingDisburse = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/emergency/list/waitingDisburse/check`,
		method: method,
		data: dataJson
	})
}
export const apiEmergencyDisburse = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/emergency/list/disburse`,
		method: method,
		data: dataJson
	})
}
export const apiEmergencyCalculate = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/emergency/calculate`,
		method: method,
		data: dataJson
	})
}
export const apiEmergencyDisburseSave = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/emergency/info/disburse/save`,
		method: method,
		data: dataJson
	})
}
export const apiEmergencyInfo = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/collections/disburse/emergency`,
		method: method,
		data: dataJson
	})
}
export const apiEmergencyCheckList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/emergency/check/list`,
		method: method,
		data: dataJson
	})
}
export const apiEmergencyCheckListSave = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/emergency/check/list`,
		method: method,
		data: dataJson
	})
}
