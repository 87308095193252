<template>
	<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
		<template slot="action" slot-scope="{ columnData }">
			<a @click="Detail(columnData.loanId)">{{ $t('page.checkDetail') }}</a>
		</template>
	</search-list-table>
</template>
<script>
import { apiEmergencyDisburse } from '@/api/emergencyLoan'
import { apiBranchList } from '@/api/branch'
export default {
	data() {
		return {
			branchOfficeList: []
		}
	},
	computed: {
		formSearchList() {
			return [
				{
					label: 'table.applyInfo.cloums.caseId',
					type: 'input',
					validateField: ['caseId']
				},
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['identityNum']
				},
				{
					label: 'table.applyInfo.cloums.payTime',
					type: 'range-picker',
					validateField: ['applyTime', { initialValue: [] }]
				},
				{
					label: 'table.CustomerManagement.loanType',
					type: 'select',
					validateField: ['loanType'],
					selectOption: [5, 6],
					prefix: 'table.applyInfo.financialLabel.loanMap'
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				},
				{
					label: 'Closed Date',
					type: 'range-picker',
					validateField: ['closeTime', { initialValue: [] }]
				}
			]
		},
		columns() {
			return [
				{
					// 案件ID
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					align: 'center',
					width: 80,
					fixed: 'left'
				},
				{
					// 案件ID
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'loanId',
					align: 'center',
					width: 150,
					fixed: 'left',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					// 车牌号
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'regNo',
					align: 'center',
					customRender: (v) => (v ? v : '-')
				},
				{
					// 身份ID
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'identityNum',
					align: 'center'
				},
				{
					// 申请金额
					title: this.$t('table.applyInfo.cloums.principalAmount'),
					dataIndex: 'applyAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 放款金额
					title: this.$t('table.applyInfo.cloums.putAmount'),
					dataIndex: 'disburseAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 支付时间
					title: this.$t('table.applyInfo.cloums.payTime'),
					dataIndex: 'paidTime',
					align: 'center'
				},
				{
					title: this.$t('table.CustomerManagement.loanType'),
					dataIndex: 'loanType',
					align: 'center',
					customRender: (v) => this.$t(`table.applyInfo.financialLabel.loanMap.${v}`)
				},
				// {
				// 	// 案件状态
				// 	title: this.$t('table.applyInfo.cloums.caseStatus'),
				// 	dataIndex: 'loanStatus',
				// 	align: 'center',
				// 	customRender: (v, o) => {
				// 		return this.$t(`table.finance.loanStatusMap.${v}`)
				// 	}
				// },
				// {
				// 	// 逾期天数
				// 	title: this.$t('table.applyInfo.cloums.overdue'),
				// 	dataIndex: 'dueDays',
				// 	align: 'center'
				// },
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 100,
					fixed: 'right'
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiEmergencyDisburse(initParams)
		},
		Detail(id) {
			this.$router.push({
				name: 'menus.customerManagementMenu.lendingdetail',
				query: {
					id
				}
			})
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>
<style scoped lang="less">
.from {
	.ant-input,
	.ant-input-number {
		width: 200px;
	}
	.ant-select {
		width: 400px;
	}
	.ant-form-item {
		margin-bottom: 10px;
	}
	.file {
		left: 0;
		top: 0;
		width: 100%;
		bottom: 0;
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}
}
</style>
