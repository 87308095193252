import { _axios } from '@/plugins/axios'

export const apiCityList = (dataJson, method = 'get') => {
  return _axios({
    url: `/car/district/city/list`,
    method: method,
    data: dataJson
  })
}
export const apiSetBranch = (dataJson, method = 'post') => {
  return _axios({
    url: `/car/store/save`,
    method: method,
    data: dataJson
  })
}
export const apiBranchManList = (dataJson, method = 'get') => {
  return _axios({
    url: `/car/store/page`,
    method: method,
    data: dataJson
  })
}
export const apiBranchList = (dataJson, method = 'get') => {
  return _axios({
    url: `/car/store/list`,
    method: method,
    data: dataJson
  })
}
export const apiBranchDel = (id, method = 'post') => {
  return _axios({
    url: `/car/store/delete/${id}`,
    method: method,
    data: {}
  })
}
export const apiCheckBranch = (dataJson, method = 'get') => {
  return _axios({
    url: `/car/store/check`,
    method: method,
    data: dataJson
  })
}

